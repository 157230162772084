




























import { Component, Prop, Vue } from 'vue-property-decorator'
import LoadingDots from './LoadingDots.vue'

@Component({ 
  components:{ 
    LoadingDots
  }
})
export default class CImage extends Vue {

  @Prop({required: true})
  alt!: string;

  @Prop()
  src!: string;

  @Prop()
  ratio!: number; //as a floating value

  @Prop()
  maxWidth!: string; //include units where possible


  get paddingTop(){ 
    const percentage = this.ratio*100;
    return `${percentage}%`;
  }

  loading = true;
  showImage = false;
  onLoad(){ 
    this.showImage = true;
  }

}
